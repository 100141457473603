
import { defineComponent } from "vue";
import {
  getBlogTags,
  getPosts,
  getPostsCount,
} from "@toppick/common/build/api/blog";
import HeaderSection from "./HeaderSection.vue";
import { BlogTag, FeaturedBlogPost } from "@toppick/common/build/interfaces";
import BlogCard from "@/components/blog/BlogCard.vue";
import Loader from "@/components/ui/Loader.vue";
import "./styles.css";
import TagsBar from "@/components/ui/TagsBar.vue";
import Pagination from "@/components/ui/Pagination.vue";
import { countPages, parsePage } from "@/utils/ui";

const RESULTS_PER_PAGE = 6;

export default defineComponent({
  components: { HeaderSection, BlogCard, Loader, TagsBar, Pagination },
  data() {
    return {
      blogs: [] as FeaturedBlogPost[],
      blogTags: [] as BlogTag[],
      isLoading: true,
      blogCounter: 0,
    };
  },
  methods: {
    async loadData() {
      const skip = (this.currentPage - 1) * RESULTS_PER_PAGE;
      this.blogCounter = await getPostsCount(this.currentTag);
      this.blogs = await getPosts(RESULTS_PER_PAGE, skip, this.currentTag);
    },

    async onTagSelect(index: number) {
      let tag: undefined | string = undefined;
      this.blogTags.forEach((tag) => (tag.active = false));
      if (this.currentTag !== this.blogTags[index].slug) {
        this.blogTags[index].active = !this.blogTags[index].active;
        tag = this.blogTags[index].slug;
      }
      this.$router.replace({
        query: { ...this.$route.query, tag, page: 1 },
      });
    },

    setPage(page: number) {
      this.$router.replace({
        query: { ...this.$route.query, page },
      });
    },
  },
  computed: {
    totalPages(): number {
      return countPages(this.blogCounter, RESULTS_PER_PAGE);
    },
    currentPage(): number {
      return parsePage(this.$route.query.page);
    },
    currentTag(): string {
      return this.$route.query.tag as string;
    },
  },
  async mounted() {
    this.blogTags = await getBlogTags();
    this.$watch(
      () => ({
        page: this.currentPage,
        tag: this.currentTag,
      }),
      async () => {
        this.isLoading = true;
        try {
          await this.loadData();
        } catch (error) {
          console.log(error);
        }
        this.isLoading = false;
      },
      { immediate: true }
    );
  },
});
