import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "body_wrap about_page" }
const _hoisted_2 = { class: "space blog_outer" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { class: "blog_inner" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-md-8" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col-md-4" }
const _hoisted_9 = { class: "blog_tags" }
const _hoisted_10 = { class: "side_bar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_section = _resolveComponent("header-section")!
  const _component_loader = _resolveComponent("loader")!
  const _component_blog_card = _resolveComponent("blog-card")!
  const _component_tags_bar = _resolveComponent("tags-bar")!
  const _component_pagination = _resolveComponent("pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_header_section),
    _createElementVNode("section", _hoisted_2, [
      _createVNode(_component_loader, { show: _ctx.isLoading }, null, 8, ["show"]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.blogs, (blog) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "col-sm-6 mb-4",
                    key: blog.id
                  }, [
                    _createVNode(_component_blog_card, { blog: blog }, null, 8, ["blog"])
                  ]))
                }), 128))
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_component_tags_bar, {
                    tags: _ctx.blogTags,
                    onOnClick: _ctx.onTagSelect,
                    disabled: false
                  }, null, 8, ["tags", "onOnClick"])
                ])
              ])
            ])
          ])
        ]),
        _createVNode(_component_pagination, {
          currentPage: _ctx.currentPage,
          totalPages: _ctx.totalPages,
          onOnChangePage: _ctx.setPage
        }, null, 8, ["currentPage", "totalPages", "onOnChangePage"])
      ])
    ])
  ]))
}