
import { PropType } from "vue";
import { defineComponent } from "@vue/runtime-core";
import "../ui/styles.css";
export default defineComponent({
  emits: ["onClick"],
  props: {
    tags: {
      type: Array as PropType<{ title: string; active?: boolean }[]>,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: true,
    },
  },
});
