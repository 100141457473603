
import { defineComponent } from "@vue/runtime-core";
import "./styles.css";
export default defineComponent({
  emits: ["onChangePage"],
  props: {
    currentPage: {
      type: Number,
      required: false,
      default: 0,
    },
    totalPages: {
      type: Number,
      required: false,
      default: 0,
    },
    maxDisplayed: {
      type: Number,
      required: false,
      default: 5,
    },
  },
  methods: {
    onChangePage(index: number) {
      this.$emit("onChangePage", index);
    },

    buttonDisplayed(index: number) {
      let IMin = 0;
      let IMax = 0;
      if (this.totalPages < this.maxDisplayed) {
        return true;
      } else if (this.currentPage > this.totalPages - this.maxDisplayed) {
        IMax = this.totalPages;
      } else if (this.currentPage < this.maxDisplayed) {
        IMax = this.maxDisplayed;
      } else {
        IMax = this.currentPage + Math.round(this.maxDisplayed / 2) + 1;
      }
      IMin = IMax - this.maxDisplayed + 1;
      return index >= IMin && index <= IMax;
    },
  },
});
