<template>
  <section class="space cmn_hero_banner inner_cmn_hero text-center blog_sec">
    <div class="container">
      <h1>Blog</h1>
      <div class="Breadcrums">
        <router-link class="cm-color" :to="{ name: 'Home' }">Home</router-link>
        <img :src="RightArrow" alt="" class="mx-2" />
        <router-link class="active text-white" :to="{ name: 'Blog' }"
          >Blog</router-link
        >
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      RightArrow: require("@/assets/images/right-arrow-black.svg"),
    };
  },
};
</script>
