import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "blog_navigation text-center mt-5" }
const _hoisted_2 = {
  key: 0,
  class: "pagination justify-content-center"
}
const _hoisted_3 = { class: "page-item" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "page-item" }
const _hoisted_7 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.totalPages > 1)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
          _createElementVNode("li", _hoisted_3, [
            _createElementVNode("button", {
              class: "page-link page_link_btn montM",
              disabled: _ctx.currentPage < 2,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onChangePage(_ctx.currentPage - 1)))
            }, " Prev ", 8, _hoisted_4)
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.totalPages, (index) => {
            return (_openBlock(), _createElementBlock("li", {
              key: index,
              class: "page-item"
            }, [
              (_ctx.buttonDisplayed(index))
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    class: _normalizeClass(["page-link montSB", { active: _ctx.currentPage === index }]),
                    onClick: ($event: any) => (_ctx.onChangePage(index))
                  }, _toDisplayString(index), 11, _hoisted_5))
                : _createCommentVNode("", true)
            ]))
          }), 128)),
          _createElementVNode("li", _hoisted_6, [
            _createElementVNode("button", {
              class: "page-link page_link_btn montM",
              disabled: _ctx.currentPage > _ctx.totalPages - 1,
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onChangePage(_ctx.currentPage + 1)))
            }, " Next ", 8, _hoisted_7)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}